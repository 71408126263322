import React, { useEffect, useState } from 'react';
import { useSession } from 'contexts/session';
import { useCore } from 'contexts/core';

const ClassesContext = React.createContext(null);

function ClassesProvider({eventId, children}) {
    const { tenant } = useSession();
    const { runNoAuthAction } = useCore();
    const [classes, setClasses] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        getClasses();
    }, []);

    const getClasses = async () => {
        let data = {'event': eventId, 'tenant': tenant.id};
        runNoAuthAction('get_all_eventclasses', data, (response) => {
            if (response.success) {
                setClasses(response.classes);
            } else {
                setError(response.message);
            }
        });
    }

    const provider = {
        classes,
        error
    }

    return <ClassesContext.Provider value={provider}>{children}</ClassesContext.Provider>
}

function useClasses() {
    const context = React.useContext(ClassesContext);
    if (!context) {
        throw new Error('useClasses must be used within a ClassesProvider');
    }
    return context;
}

export { ClassesProvider, useClasses }