import React, { useEffect, useState } from 'react';
import { useCore } from './core';

const SocialContext = React.createContext(null);

const LIMIT = 25;

function SocialProvider({children}) {
    let offset = 0;
    const { runAction } = useCore();
    const [postsLoading, setPostsLoading] = useState(false);
    const [posts, setPosts] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        if (!postsLoading) {
            getPosts();
        }
    }, []);

    const getPosts = async () => {
        setPostsLoading(true);
        const data = {LIMIT, offset};
        await runAction('get_posts', data, (response) => {
            if (response.success) {
                if (offset === 0) {
                    setPosts(response.posts);
                } else if (response.posts.length > 0) {
                    setPosts(posts.concat(response.posts));
                }
                if (response.posts.length < 25) {
                    setHasMore(false);
                }
            }
            setPostsLoading(false);
        });
    }

    const getMore = () => {
        offset += LIMIT;
        getPosts();
    }

    const getPost = async (postId) => {
        const data = {postId};
        await runAction('get_post', data, (response) => {
            return response;
        });
        return null;
    }

    const savePost = async (data, callback) => {
        const action = data.id ? 'update_post' : 'create_post';
        runAction(action, data, (response) => {
            if (response.success) {
                getPosts();
                callback();
            }
        });
    }

    const deletePost = async (postId) => {
        let data = {'postId': postId};
        runAction('delete_post', data, (response) => {
            if (response.success) {
                getPosts();
            }
        });
    }

    const provider = {
        postsLoading,
        posts,
        getPosts,
        hasMore,
        getMore,
        getPost,
        savePost,
        deletePost,
    }

    return <SocialContext.Provider value={provider}>{children}</SocialContext.Provider>
}

function useSocial() {
    const context = React.useContext(SocialContext);
    if (!context) {
        throw new Error('useSocial must be used within a SocialProvider');
    }
    return context;
}

export { SocialProvider, useSocial }