import React, { useEffect, useState } from 'react';
import { usePurchaseOrder } from './purchaseOrder';

const PersonalInfoContext = React.createContext(null);

const PIKEY = 'personalInfo';

function PersonalInfoProvider({children}) {
    const { completed } = usePurchaseOrder();
    const [isLoading, setIsLoading] = useState(true);
    const [memberId, setMemberId] = useState(null);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('US');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [isPiComplete, setIsPiComplete] = useState(false);

    useEffect(() => {
        setIsPiComplete(fname?.length > 0 
            && lname?.length > 0 
            && address1?.length > 0 
            && city?.length > 0
            && state?.length > 0 
            && zip?.length > 0
            && country?.length > 0
            && phone?.length > 0 && email?.length > 0);
    }, [fname, lname, address1, city, state, zip, country, phone, email])

    useEffect(() => {
        if (!isLoading && window.sessionStorage) {
            const piJSONString = JSON.stringify({memberId, fname, lname, address1, address2, city, state, zip, country, phone, email});
            window.sessionStorage.setItem(PIKEY, piJSONString);
        }
    }, [memberId, fname, lname, address1, address2, city, state, zip, country, phone, email])

    useEffect(() => {
        if (window.sessionStorage) {
            const piJSONString = window.sessionStorage.getItem(PIKEY);
            if (piJSONString) {
                try {
                    const piJSON = JSON.parse(piJSONString);
                    setMemberId(piJSON.memberId);
                    setFname(piJSON.fname);
                    setLname(piJSON.lname);
                    setAddress1(piJSON.address1);
                    setAddress2(piJSON.address2);
                    setCity(piJSON.city);
                    setState(piJSON.state);
                    setZip(piJSON.zip);
                    setCountry(piJSON.country);
                    setPhone(piJSON.phone);
                    setEmail(piJSON.email);
                } catch (err) {
                    console.error(err);
                } finally {
                    window.sessionStorage.removeItem(PIKEY);
                }
            }
        }
        setIsLoading(false);
    }, [])

    useEffect(() => {
        if (completed && window.sessionStorage) {
            window.sessionStorage.removeItem(PIKEY);
        }
    }, [completed])

    const resetPi = () => {
        window.sessionStorage.removeItem(PIKEY);
    }

    const provider = {
        memberId, setMemberId,
        fname, setFname,
        lname, setLname,
        address1, setAddress1,
        address2, setAddress2,
        city, setCity,
        state, setState,
        zip, setZip,
        country, setCountry,
        phone, setPhone,
        email, setEmail,
        isPiComplete,
        resetPi
    }

    return <PersonalInfoContext.Provider value={provider}>{children}</PersonalInfoContext.Provider>
}

function usePersonalInfo() {
    const context = React.useContext(PersonalInfoContext);
    if (!context) {
        throw new Error('usePersonalInfo must be used within a PersonalInfoProvider');
    }
    return context;
}

export { PersonalInfoProvider, usePersonalInfo }