import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngry, faComment, faGrin, faHeart, faLaugh, faPencil, faSadTear, faSurprise, faThumbsDown, faThumbsUp, faTrash } from '@fortawesome/pro-regular-svg-icons';
import Reactions from './socialReactions';
import ImageViewer from 'utils/imageViewer';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useReactions } from 'contexts/reactions';
import { useComments } from 'contexts/comments';
import { useSettings } from 'contexts/settings';
import Avatar from 'components/avatar';

const reactionIcons = [faThumbsUp, faThumbsDown, faHeart, faGrin, faLaugh, faSurprise, faSadTear, faAngry];

function SocialPost({post, onEdit, onDelete, onComment}) {
    const { member } = useAuth();
    const { dict } = useLang();
    const { reactions, createReaction } = useReactions();
    const { comments, getComments } = useComments();
    const { settings } = useSettings();
    const [postReactions, setPostReactions] = useState(post.reactions);
    const [postComments, setPostComments] = useState([]);
    const [showReactionChooser, setShowReactionChooser] = useState(false);

    useEffect(() => {
        if (reactions.length > 0) {
            setPostReactions(reactions);
        }
    }, [reactions])

    useEffect(() => {
        if (comments.length > 0) {
            setPostComments(comments);
        }
    }, [comments])

    const toggleReactionChooser = () => {
        setShowReactionChooser(!showReactionChooser);
    }
    const hideReactionChooser = () => {
        setShowReactionChooser(false);
    }
    const reactionHandler = async (reaction) => {
        setShowReactionChooser(false);
        createReaction(reaction);
    }
    const editHandler = () => {
        onEdit(post);
    }
    const deleteHandler = () => {
        onDelete(post);
    }
    const commentHandler = () => {
        onComment(post);
    }
    const showComments = () => {
        if (postComments.length > 0) {
            setPostComments([]);
            return;
        } else if (post.post.commentCount > 0) {
            getComments();
        }
    }
    const getMaxReaction = () => {
        let maxReaction = null;

        if (postReactions.length > 0) {
            let rCounter = [0, 0, 0, 0, 0, 0, 0, 0];
            postReactions.forEach((r) => ++rCounter[r.reaction]);
            let maxIdx = 0, maxVal = rCounter[0];
            rCounter.forEach((c, i) => {if(c > maxVal){maxIdx=i;maxVal=c;}});
            maxReaction = reactionIcons[maxIdx];
        }
        return maxReaction;
    }

    return(
        <div className='post-tile' onMouseLeave={hideReactionChooser}>
            <div className='post-header'>
                <div className='post-author-info'>
                    <Avatar value={post.post.avatar} width='40' height='40' name={post.post.author}/>
                    <div className='post-info'>
                        <div className='post-author'>by {post.post.author}</div>
                        <div className='post-date'>on {moment(post.post.created).format('MMMM Do YYYY')}</div>
                    </div>
                </div>
                    {(post.post.memberId === member?.id || settings.moderator)
                    ? <div className='post-owner-options'>
                            <div className='owner-option' role='button' aria-label='edit' onClick={editHandler}><FontAwesomeIcon icon={faPencil} /></div>
                            <div className='owner-option' role='button' aria-label='delete' onClick={deleteHandler}><FontAwesomeIcon icon={faTrash} /></div>
                        </div>
                    : null}
                
            </div>
            <div className='post-message'>{post.post.message}</div>
            {post.media.length > 0 
                ? <ImageViewer images={post.media} />
                : null}
            <div className='post-activity'>
                <div className='post-likes'>{getMaxReaction() ? <FontAwesomeIcon icon={getMaxReaction()}/> : null} {postReactions.length} {dict.reactions}</div>
                <div className='post-comments' onClick={showComments}>{post.post.commentCount} {dict.comments}</div>
            </div>
            <div className='post-actions'>
                <Reactions showing={showReactionChooser} onReaction={reactionHandler} />
                <div className='action' role='button' onClick={toggleReactionChooser} ><FontAwesomeIcon icon={faThumbsUp}/> {dict.like}</div>
                <div className='action' role='button' onClick={commentHandler}><FontAwesomeIcon icon={faComment} /> {dict.comment}</div>
            </div>
            { postComments.length > 0 
                ? <div className='post-comments-list'>
                    {postComments.map((c) => 
                        <div key={c.post.id} className='post-comment'>
                            <div className='comment-info'>by {c.post.author} on {moment(c.post.created).format('MMMM Do YYYY')}</div>
                            <div className='comment-message'>{c.post.message}</div>
                        </div>)}
                </div> 
                : null}
        </div>
    )
}

export default SocialPost;