import React, { useEffect, useState } from 'react';
import moment from 'moment';
import NextButton from 'utils/nextbutton';
import { useLang } from 'contexts/lang';
import { useAuth } from 'contexts/auth';
import { useSettings } from 'contexts/settings';

function Membership() {
    const { dict } = useLang();
    const { member } = useAuth();
    const { settings, add1Year, saveSettings } = useSettings();
    const [expiration, setExpiration] = useState(member?.expiration);
    const [renewing, setRenewing] = useState(false);
    const [autoRenew, setAutoRenew] = useState(settings.autoRenew);

    useEffect(() => {
        if (autoRenew !== settings.autoRenew) {
            const settingsData = {...settings, autoRenew};
            saveSettings(settingsData, () => {
                alert('Automatic renewal has been turned ' + (autoRenew ? 'on' : 'off') + '.')
            });
        }
    }, [autoRenew])

    const add1YearHandler = async () => {
        setRenewing(true);
        try {
            await add1Year((newExpiration) => {
                setExpiration(newExpiration);
                alert('Your membership has been renewed for another year.');
            });
        } catch (e) {
            alert(e.message);
        } finally {
            setRenewing(false);
        }
    }

    return (
        <div className='settings-view'>
            <h3>{dict.membershipRenewal}</h3>
            <div className='setting-item'>
                <div>
                    <label style={{fontWeight:'bold'}}>{dict.membershipExpirationDate} {moment(expiration).format('YYYY-MM-DD')}</label>
                    <div>
                        {moment(expiration).diff(moment(), 'days') < 30 &&
                            <>
                                <div className='button-container'><NextButton onClick={add1YearHandler} label='Renew now' waitMode={renewing} /></div>
                                <p>{dict.membershipRenewalStatement}</p>
                            </>}
                    </div>
                    <p>{dict.autoRenewalStatement}</p>
                    <div>{dict.autoRenew} <input type='checkbox' checked={autoRenew} onChange={e => setAutoRenew(e.target.checked)} /></div>
                </div>
            </div>
        </div>
    )
}

export default Membership