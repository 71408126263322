import { formatCurrency } from '_base';
import Loading from 'components/loading';
import { useMemberTypes } from 'contexts/memberTypes';
import { MembershipInfoProvider } from 'contexts/purchasing/membershipInfo';
import { PersonalInfoProvider } from 'contexts/purchasing/personalInfo';
import { PurchaseOrderProvider } from 'contexts/purchasing/purchaseOrder';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import MembershipJoin from './membershipJoin';

export async function loader({params}) {
    return {typeId: params.typeId};
}

function Join() {
    const { isLoading, types } = useMemberTypes();
    const loaderData = useLoaderData();
    const [membershipType, setMembershipType] = useState(null);

    useEffect(() => {
        if (loaderData && loaderData.typeId && types.length > 0) {
            const type = types.find(t => t.id == loaderData.typeId);
            setMembershipType(type);
        }
    }, [loaderData, types])

    return (
        <div className='events-container'>
            {
                loaderData && loaderData.typeId ?
                    <PurchaseOrderProvider type='membershipPO'>
                        <PersonalInfoProvider>
                            <MembershipInfoProvider>
                                <MembershipJoin membershipType={membershipType} />
                            </MembershipInfoProvider>
                        </PersonalInfoProvider>
                    </PurchaseOrderProvider>
            :
                <>
                    <div className='events-label'>Become a member</div>
                    <div className='event-panels'>
                        {isLoading ? <Loading/> : types.length === 0
                            ? <div className='no-events-message'>There are no members types that you can join at this time, check back later.</div> 
                            :  types.map(type => <TypePanel key={type.id} type={type}/>)}
                    </div>
                </>
            }
        </div>
    )
}

function TypePanel({type}) {
    const navigate = useNavigate();

    const joinHandler = () => {
        navigate('/portal/join/' + type.id);
    }

    return (
        <div className='event-panel'>
            <div className='event-panel-title'>{type.label}</div>
            <div className='event-panel-button'>
                <div style={{ whiteSpace: 'pre-wrap',textAlign:'center' }}>{type.description.length > 0 ? type.description : 'No description'}</div>
                <hr/>
                {type.anniversaryRenewal 
                    ? <p>This membership type renews annually on {moment(type.anniversaryDate).format('MMMM Do')}.</p>
                    : <p>This membership will renew annually on this day.</p>
                }
                <button onClick={joinHandler}>Join Now {formatCurrency(type.price)}</button>
            </div>
        </div>
    )
}

export default Join;