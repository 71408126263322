import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useLang } from 'contexts/lang';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { useRegistrationInfo } from 'contexts/purchasing/registrationInfo';
import { useSession } from 'contexts/session';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useMembershipInfo } from 'contexts/purchasing/membershipInfo';
import { useEventCompletion } from 'contexts/eventCompletion';
import { useMembershipCompletion } from 'contexts/membershipCompletion';
import { useProductsCompletion } from 'contexts/productsCompletion';
import { useWorkerInfo } from 'contexts/purchasing/workerInfo';

function CompleteEvent({eventId}) {
    const { tenant } = useSession();
    const { registrations, passes, spaces, tickets, selectedShifts, setCompleted } = usePurchaseOrder();
    const { fname, lname, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { fname: workerFname, lname: workerLname, phone: workerPhone, email: workerEmail, swag } = useWorkerInfo();
    const { year, make, model, trim, color, engine, carClass } = useRegistrationInfo();
    const { dict } = useLang();
    const { createEntry, createParticipant, createTicket, workerSignup } = useEventCompletion();
    
    let ticketCount = 0;
    const [creatingEntry, setCreatingEntry] = useState(false);
    const [creatingSpaces, setCreatingSpaces] = useState(false);
    const [creatingTickets, setCreatingTickets] = useState(false);
    const [signingUp, setSigningUp] = useState(false);
    const [error, setError] = useState(null);

    const [completedEntry, setCompletedEntry] = useState(true);
    const [completedSpaces, setCompletedSpaces] = useState(true);
    const [completedTickets, setCompletedTickets] = useState(true);
    const [completedSignup, setCompletedSignup] = useState(true);

    useEffect(() => {
        if (registrations > 0) {
            setCompletedEntry(false);
            createEntryHandler();
        }
        if (spaces.length > 0) {
            setCompletedSpaces(false);
            createSpacesHandler();
        }
        if (tickets.length > 0) {
            setCompletedTickets(false);
            createTicketHandler();
        }
        if (selectedShifts.length > 0) {
            setCompletedSignup(false);
            signUp();
        }
    }, [])

    useEffect(() => {
        setCompleted(completedEntry && completedSpaces && completedTickets && completedSignup);
    }, [completedEntry, completedSpaces, completedTickets, completedSignup])
    /*
        Create registration entries
    */
    const createEntryHandler = async () => {
        setCreatingEntry(true);
        let data = {
            tenantUUID: tenant.uuid,
            event: eventId,
            fname, lname, address1, address2, city, state, zip, country, phone, email,
            year, make, model, trim, color, engine, car_class: carClass,
            additionalPasses: passes.length,
            notes: 'DART:Online Purchase'
        };
        await createEntry(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingEntry(false);
            setCompletedEntry(true);    
        });
    }

    const createSpacesHandler = async () => {
        setCreatingSpaces(true);
        const vendor = {eventId, name: fname + ' ' + lname, address1, address2, city, state, zip, country, phone, email, notes: 'Online purchase'};
        const elementIds = spaces.map(r => r.elem);
        let data = {tenantId: tenant.id, eventId, vendor, spaces: elementIds};
        await createParticipant(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingSpaces(false);
            setCompletedSpaces(true);
        });
    }
    /*
        create tickets
    */
    const createTicketHandler = async () => {
        setCreatingTickets(true);
        const ticket = tickets[ticketCount];
        let data = {
            tenantUUID: tenant.uuid,
            event: eventId,
            eventticket: ticket.id,
            name: fname + ' ' + lname,
            email
        };
        await createTicket(data, (response) => {
            if (response.success) {
                ++ticketCount;
                if (ticketCount < tickets.length) {
                    createTicketHandler();
                }
            } else {
                setError(response.errorMessage);
            }
            setCreatingTickets(false);
            setCompletedTickets(true);
        });
    }
    /* 
        sign up for shifts
    */
    const signUp = async () => {
        setSigningUp(true);
        const contactInfo = {fname: workerFname, lname: workerLname, email: workerEmail, phone: workerPhone, swag, notes: 'Online signup'}
        let data = {
            tenantId: tenant.id, 
            eventId: eventId,
            contactInfo: contactInfo, 
            shifts: selectedShifts.map(s => s.id)}
        await workerSignup(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setSigningUp(false);
            setCompletedSignup(true);
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            {registrations > 0 &&
                <div className='completeSection'>
                    {creatingEntry ?
                        <div>
                            <h2>{dict.processingRegistration1} {fname}</h2>
                            <p>{dict.processingRegistration2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{dict.processingRegistration3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
            {spaces.length > 0 &&
                <div className='completeSection'>
                    {creatingSpaces ?
                        <div>
                            <h2>{dict.spaces1.replace('{0}' , spaces.length).replace('{1}', fname)}</h2>
                            <p>{dict.spaces2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin/></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{spaces.length} {dict.spaces3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
            {tickets.length > 0 &&
                <div className='completeSection'>
                    {creatingTickets ? 
                        <div>
                            <h2>{dict.ticket1.replace('{0}' , tickets.length).replace('{1}', fname)}</h2>
                            <p>{dict.ticket2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin/></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{tickets.length} {dict.ticket3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
            {selectedShifts.length > 0 &&
                <div className='completeSection'>
                    {signingUp ?
                        <div>
                            <h2>{dict.processingWorkerSignup1} {fname}</h2>
                            <p>{dict.processingWorkerSignup2}</p>
                            <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                        </div>
                        :
                        <div>
                            <h2>{dict.thankYou}</h2>
                            <p>{dict.processingWorkerSignup3}</p>
                            <p>{dict.clickFinished}</p>
                            <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

function CompleteMembership() {
    const { tenant } = useSession();
    const { setCompleted } = usePurchaseOrder();
    const { fname, lname, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { emailNewsletter, mailNewsletter, listInDirectory, comments, notify, type, tags} = useMembershipInfo();
    const { dict } = useLang();
    const { createMembership } = useMembershipCompletion();
    const [error, setError] = useState(null);

    const [creatingMembership, setCreatingMembership] = useState(false);

    useEffect(() => {
        createMembershipHandler();
    }, [])

    const createMembershipHandler = async() => {
        setCreatingMembership(true);
        let data = {
            'tenantUUID': tenant.uuid,
			'fname': fname,
			'lname': lname,
			'address': address1 + ' ' + address2,
			'city': city,
			'state': state,
			'zip': zip,
		    'phoneNumbers': [],
			'email_address': email,
			'email_newsletter': emailNewsletter,
			'mail_newsletter': mailNewsletter,
		    'list_in_directory': listInDirectory,
			'comments': comments,
			'notify': notify,
			'type': type,
			'tags': tags
        };
        if (phone.length > 0) {
            data.phoneNumbers.push({'typeId': 1, 'isoCode': 'US', 'countryCode': '+1', 'longCode': phone});
        }
        await createMembership(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setCreatingMembership(false);
            setCompleted(true);    
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                {creatingMembership ?
                    <div>
                        <h2>{dict.processingMembership1} {fname}</h2>
                        <p>{dict.processingMembership2}</p>
                        <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                    </div>
                    :
                    <div>
                        <h2>{dict.thankYou}</h2>
                        <p>{dict.processingMembership3}</p>
                        <p>{dict.clickFinished}</p>
                        <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                    </div>    
                }
            </div>
        </div>
    )
}

function CompleteDonation() {
    const { setCompleted } = usePurchaseOrder();
    //const { fname, lname, address1, address2, city, state, zip, country, phone, email } = usePersonalInfo();
    const { dict } = useLang();
    const [error, setError] = useState(null);

    useEffect(() => {
        setCompleted(true);
    }, [])

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                <div>
                    <h2>{dict.thankYou}</h2>
                    <p>{dict.donationThanks}</p>
                    <p>{dict.clickFinished}</p>
                    <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                </div>
            </div>
        </div>
    )
}

function CompleteProducts() {
    const { tenant } = useSession();
    const { products, setCompleted } = usePurchaseOrder();
    const { fname, lname, address1, city, state, zip, phone, email } = usePersonalInfo();
    const { dict } = useLang();
    const { sellProducts } = useProductsCompletion();
    const [error, setError] = useState(null);
    const [sellingProduct, setSellingProduct] = useState(false);

    useEffect(() => {
        sellProductsHandler();
    }, [])

    const sellProductsHandler = async () => {
        setSellingProduct(true);
        let cart = [];
        products.forEach((p) => {
            let inv = p.inventories[0];
            inv.productName = p.name;
            inv.productId = p.id;
            cart.push({inventory: inv, quantity: 1});
        });
        let data = { 
            'tenant': tenant.id,
            'cart': cart,
            //"promos": null,
            'shipping': {name: fname + ' ' + lname, address: address1, city, state, zip, phone, email},
            //"receipt": receipt
        };
        await sellProducts(data, (response) => {
            if (!response.success) {
                setError(response.errorMessage);
            }
            setSellingProduct(false);
            setCompleted(true);    
        });
    }

    return (
        <div>
            {error && <div>{error}</div>}
            <div className='completeSection'>
                {sellingProduct ? 
                    <div>
                        <h2>Processing order...</h2>
                        <p>Do not hit your browser back button or close the window until this has completed.</p>
                        <div className='completeIcon'><FontAwesomeIcon icon={faSpinner} spin /></div>
                    </div>
                :
                    <div className='shipping-info'>
                        <h2>{dict.thankYou}</h2>
                        <p>{dict.purchaseComplete}</p>
                        <div className='completeIcon done'><FontAwesomeIcon icon={faCheckCircle} /></div>
                    </div>
                }
            </div>
        </div>
    )
}

export { CompleteEvent, CompleteMembership, CompleteDonation, CompleteProducts }